<ng-container *ngIf="!showCongratulations; else congratsTemplate">
  <section class="template-bg">

    <header class="logo-header">
      <div class="logo-title">
        <div>
          <!-- <img *ngIf="data.image"  loading="lazy" [src]="data.image" alt="Logo Image" class="logo-image"> -->
          <img loading="lazy"
            [src]="'https://assets-staging.sustain-suite.com/uploads/1515017d621ce67ea832d22034767630.png' "
            alt="Logo Image" class="logo-image">

        </div>
        <div class="assessment_label_bg">{{ data?.title | capitalizeFirst }}</div>
      </div>
      <div class="phone-number-bg">
        <mat-icon class="phone-icon">phone</mat-icon>
        <span class="phone-number"><a class="phoneNumber" [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a></span>
      </div>
    </header>
    <section class="template-body-bg">
      <ng-container *ngIf="data?.sections.length">
        <ng-container>
          <ng-container *ngIf="data?.sections[activeStep]?.questions?.length">
            <div class="question-table">
              <ng-container
                *ngFor="let section of (isPositive || isActual ? filteredSection  : data.sections);let sectionIndex=index">
                <ng-container *ngFor="let quesAns of section?.questions; let questionIndex = index">
                  <div class="table-cell" [id]="'question-' + sectionIndex + '-' + questionIndex">
                    <!-- <div class="ques_num_label">{{ getGlobalQuestionIndex(sectionIndex, questionIndex) + 1 }}.</div> -->

                    <!-- <div class="ques_num_label" *ngIf="quesAns.type !== 'section'">
                      Q.{{ getGlobalQuestionIndex(sectionIndex, questionIndex) + 1 }}
                    </div> -->
    
                    <!-- <div class="ques_num_label" *ngIf="quesAns.type !== 'section' && section['title'] === '' && !typeEmailToPreview">
                      Q.{{ getGlobalQuestionIndex(sectionIndex, questionIndex) + 1 }}
                    </div> -->
    
                    <div class="ques_num_label" *ngIf="quesAns.type !== 'section' && section['title'] === '' && typeEmailToPreview && getGlobalQuestionIndex(sectionIndex, questionIndex) !== 0">
                      Q.{{ getGlobalQuestionIndex(sectionIndex, questionIndex) }}
                    </div>






                    <div class="card_bg_custom custom_panel_expansion">
                      <div >
                        <ng-container *ngIf="quesAns.text">
                          <span class="question_label" >{{ quesAns.text | capitalizeFirst }}</span>
                          <span style="line-height:2" [innerHTML]="quesAns.description" *ngIf="isHtmlContent(quesAns.description)"></span>
                          <ng-container *ngFor="let option of quesAns.options">
                            <mat-icon class="cursor-pointer fs_14" *ngIf="option.hint" [matTooltip]="option.hint">help</mat-icon>
                          </ng-container>
                          <span *ngIf="quesAns.required" class="required-asterisk">*</span>
                          <div class="question_description_section mgn-up-10" *ngIf="quesAns.description && !isHtmlContent(quesAns.description)">
                            {{ quesAns.description | capitalizeFirst }}
                          </div>
                        </ng-container>
                      </div>
                      <div class="input-container">
                        <!-- <ng-container *ngIf="quesAns.type === 'checkbox' ">
                          <ng-container *ngFor="let option of quesAns.options; let optionIndex = index">
                            <label class="custom-checkbox">
                              <input type="checkbox" [(ngModel)]="option.checked"
                                (change)="onCheckboxChange(quesAns, option)">
                              <span class="checkmark"></span>
                              {{ option.value.answer | capitalizeFirst }}
                            </label>
                          </ng-container>
                        </ng-container> -->

                        <ng-container *ngIf="quesAns.type === 'checkbox'">
                          <ng-container *ngFor="let option of quesAns.options; let optionIndex = index">
                            <label class="custom-checkbox" *ngFor="let item of option.answer">
                              <input type="checkbox" [(ngModel)]="item.checked" (change)="onCheckboxChange(quesAns, item)">
                              <span class="checkmark"></span>{{ item.text | capitalizeFirst }}
                            </label>
                          </ng-container>
                        </ng-container>
                        
                        
                        <ng-container *ngIf="quesAns.type === 'radio'">
                          <ng-container *ngFor="let option of quesAns.options">
                            <ng-container *ngFor="let dropdownOption of option.answer">
                              <label class="custom-radio">
                                <input type="radio" name="radioGroup{{sectionIndex}}-{{questionIndex}}" 
                                [(ngModel)]="quesAns.selectedOption"
                                  (ngModelChange)="onRadioChange(quesAns, $event, sectionIndex, questionIndex)"
                                  [value]="dropdownOption">
                                <span class="radiomark"></span>
                                {{dropdownOption | capitalizeFirst }}
                              </label>
                            </ng-container>

                            <!-- <label class="custom-radio">
                              <input type="radio" name="radioGroup{{questionIndex}}"
                                [(ngModel)]="quesAns.selectedOption"
                                (ngModelChange)="onRadioChange(quesAns, $event, sectionIndex, questionIndex)"
                                [value]="option.answer">
                              <span class="radiomark"></span>
                              {{ option.answer | capitalizeFirst }}
                            </label> -->

                            
                          </ng-container>
                        </ng-container>

                        
                        <ng-container *ngIf="quesAns.type === 'textarea'">
                          <ng-container *ngFor="let option of quesAns.options">
                            <textarea class="custom-textarea" [(ngModel)]="option.answer" #textarea maxlength="{{option?.limit}}"
                              (ngModelChange)="updateCompletion()" [placeholder]="option?.placeholder"></textarea>
                               <div class="mat_hint_custom">
                                <mat-hint *ngIf="option.limit" align="end">Max {{textarea.value.length}}/{{option.limit}} characters</mat-hint>
                               </div>
                          </ng-container>
                        </ng-container>



                        <ng-container *ngIf="quesAns.type === 'input'">
                          <ng-container *ngFor="let option of quesAns.options">
                            <input disabled="{{quesAns.text.includes('IRO tracking')}}" #input
                              type="{{quesAns.inputType || 'text'}}" class="custom-input" [(ngModel)]="option.answer"
                              (ngModelChange)="updateCompletion()" [placeholder]="option?.placeholder">
                              <div class="mat_hint_custom">
                                <mat-hint *ngIf="option.limit" align="end">Max {{input.value.length}}/{{option.limit}} characters</mat-hint>
                               </div>
                          </ng-container>
                        </ng-container>

                        <!-- <ng-container *ngIf="quesAns.type === 'dropdown'">
                          <ng-container *ngFor="let option of quesAns.options">
                            <ng-container *ngIf="option.multipleDropdown !=='multiple_select'">
                              <select class="custom-input" [(ngModel)]="quesAns.answer" [multiple]="option.multipleDropdown==='multiple_select'">
                                <option *ngFor="let dropdownOption of option.answer"
                                  [value]="dropdownOption" [placeholder]="option?.placeholder">{{dropdownOption}}</option>
                              </select>
                            </ng-container>

                            <ng-container *ngIf="option.multipleDropdown === 'multiple_select'">
                              <ng-container *ngFor="let dropdownOption of option.answer">
                                <label class="custom-checkbox">
                                  <input 
                                    type="checkbox" 
                                    [value]="dropdownOption" 
                                    [checked]="quesAns.answer?.includes(dropdownOption)" 
                                    (change)="onCheckboxChangeForMultiselect(quesAns, dropdownOption)" 
                                  />
                                  <span class="checkmark"></span>
                                  {{ dropdownOption }}
                                </label>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container> -->

                        <ng-container *ngIf="quesAns.type === 'dropdown'">
                          <ng-container *ngFor="let option of quesAns.options">
                              <ng-container *ngIf="option.multipleDropdown !== 'multiple_select'">

                                  <select class="custom-input" [(ngModel)]="quesAns.answer" (change)="onDropdownChange(quesAns, $event)">
                                    <option *ngFor="let dropdownOption of option.answer; trackBy: trackByFn"
                                            [value]="dropdownOption">{{dropdownOption}}</option>
                                </select>
                                
                              </ng-container>
                      
                              <ng-container *ngIf="option.multipleDropdown === 'multiple_select'">
                                  <ng-container *ngFor="let dropdownOption of option.answer">
                                      <label class="custom-checkbox">
                                          <input 
                                              type="checkbox" 
                                              [value]="dropdownOption" 
                                              [checked]="quesAns.answer?.includes(dropdownOption)" 
                                              (change)="onCheckboxChangeForMultiselect(quesAns, dropdownOption)" 
                                          />
                                          <span class="checkmark"></span>
                                          {{ dropdownOption }}
                                      </label>
                                  </ng-container>
                              </ng-container>
                          </ng-container>
                      </ng-container>
                      
                      
                      

                        <ng-container *ngIf="quesAns.type === 'signature'">
                          <div>
                            <canvas (mousedown)="startDrawing($event)" (mousemove)="draw($event)"
                              (mouseup)="stopDrawing()" (mouseleave)="stopDrawing()" #signaturePad id="signature-pad"
                              width="600" height="200" class="signature-canvas">
                            </canvas>

                            <div class="signature-controls">
                              <input class="pen_input" type="color" id="penColor" [(ngModel)]="penColor"
                                placeholder="Pen color" (change)="updatePenColor()" #fileUpload style="display: none;"
                                name="fileInput" />
                              <button mat-raised-button style="color: #fff;" [style.background]="penColor"
                                [matTooltip]="'Pen color'" (click)="fileUpload.click()"
                                [style.background]="penColor"><mat-icon>create</mat-icon>Pen</button>

                              <button type="button" class="close-btn-txt" mat-button
                                (click)="clearSignature()"><mat-icon>cancel</mat-icon>Clear</button>
                              <button (click)="undo()" mat-raised-button
                                class=" undo_btn"><mat-icon>undo</mat-icon>Undo</button>
                              <button (click)="redo()" mat-raised-button
                                class=" redo_btn"><mat-icon>redo</mat-icon>Redo</button>
                              <button mat-raised-button class="save_btn" (click)="saveSignature(quesAns)">Save</button>
                              <!-- <button mat-raised-button (click)="toggleAutoSignature()">Auto Signature</button> -->
                            </div>
                            <input type="hidden" [(ngModel)]="signatureData" />
                          </div>
                        </ng-container>

                        <ng-container *ngIf="quesAns.type === 'fileUpload'">
                          <div>
                            <ng-container *ngIf="defultImageUrl ">
                              <div class="text-center img_bg"><img alt="Answer image" src="{{defultImageUrl}}" /></div>
                            </ng-container>
                            <div class="d-flex img_bg_image" *ngIf="!defultImageUrl">
                              
                              <ng-container >
                                <ng-container *ngFor="let option of quesAns.options">
                                  <ng-container *ngIf="option['uploadingFile']==='single'">
                                    <ng-container *ngFor="let file of uplodedFiles">
                                      <div class="text-center">
                                        <ng-container *ngIf="isImage(file.url)">
                                          <img *ngIf="uplodedFiles.length" alt="Answer image 1" src="{{uplodedFiles[uplodedFiles.length - 1]['url']}}" />
                                        </ng-container>
                                        <ng-container *ngIf="!isImage(file.url)">
                                          <mat-icon> insert_drive_file</mat-icon>{{file.name}}
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngIf="option['uploadingFile'] !=='single'">
                                    <div class="text-center d-flex justify_content_start gap_10 flex-wrap">
                                      <ng-container *ngFor="let file of uplodedFiles;let i = index">
                                        <ng-container *ngIf="isImage(file.url)">
                                          <img alt="Answer image 2" class="multipleImage" src="{{file.url}}" />
                                        </ng-container>
                                        <ng-container *ngIf="!isImage(file.url)">
                                          <mat-icon> insert_drive_file</mat-icon>{{file.name}}
                                        </ng-container>
                                        <!-- <mat-icon class="delete_btn_multiple" (click)="deleteFile(i)">delete</mat-icon> -->
                                      
                                      </ng-container>
                                    </div>
                                  </ng-container>
                              
                                </ng-container>
                              </ng-container>
                             

                              
                            </div>
                            <div>
                              <form>
                                <ng-container>
                                  <ng-container *ngFor="let option of quesAns.options">
                                    <input [(ngModel)]="option.answer" #fileUpload style="display: none;" type="file"
                                      id="fileInput" name="fileInput" [multiple]="option.uploadingFile !=='single'"
                                      [accept]="option.fileType" (change)="selectFiles($event,quesAns)"/>
                                    <!-- (change)="selectFiles($event)" -->
                                    <button (click)="fileUpload.click()"
                                      mat-button><mat-icon>attachment</mat-icon>Upload</button>
                                    <button [matTooltip]="'Accept only ' +option?.fileType?.replace('.', '') + ' type'"
                                      mat-button><mat-icon>help</mat-icon>Help</button>

                                  </ng-container>
                                </ng-container>
                              </form>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="quesAns.type === 'ratingScale'">
                          <div class="rating-scale">
                            <ng-container *ngFor="let option of quesAns.options">
                              <div class="rating-option" *ngFor="let answer of option.answer; let i = index"
                                (click)="toggleRating(option, i)">
                                <mat-icon *ngIf="answer === 'selected'" class="selected-icon answer_icon">{{
                                  option.selectedIcon }}</mat-icon>
                                <mat-icon *ngIf="answer !== 'selected'" class="unselected-icon answer_icon">{{
                                  option.selectedIcon }}</mat-icon>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!quesAns.uploading && quesAns.fileUpload">
                          <div class="w-100">
                            <input #fileUpload style="display: none;" type="file" id="fileInput" name="fileInput"
                              accept=".doc,.docx,.pdf,.png,.jpg,.jpeg,.xls,.xlsx"
                              (change)="onFileChange($event, quesAns)" multiple />
                            <button (click)="fileUpload.click()" class="upload-btn"
                              matTooltip="Only PDF, Word, Excel, and Image files can be uploaded">
                              <mat-icon>attachment</mat-icon> Upload
                            </button>
                          </div>
                        </ng-container>
                        <div class="w-100 img_container" *ngIf="quesAns.fileUpload">
                          <ng-container *ngIf="quesAns.uploadedFiles?.length">
                            <div class="text-center">
                              <ng-container *ngFor="let file of quesAns.uploadedFiles; let fileIndex = index">
                                <div class="file-container">
                                  <div class="d-flex justify_content_between align_item_center w-100">
                                    <ng-container [ngSwitch]="sharedService.uploadedFileType(file?.name)">
                                      <div class="d-flex align_item_center w-100">
                                        <fa-icon *ngSwitchCase="'pdf'" [icon]="faFilePdf" class="file-icon" size="2x"
                                          aria-hidden="true" alt="PDF File"
                                          (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                        <fa-icon *ngSwitchCase="'doc'" [icon]="faFileWord" class="file-icon" size="2x"
                                          aria-hidden="true" alt="Document File"
                                          (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                        <fa-icon *ngSwitchCase="'xlsx'" [icon]="faFileExcel" class="file-icon" size="2x"
                                          aria-hidden="true" alt="Excel File"
                                          (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                        <fa-icon *ngSwitchCase="'image'" [icon]="faFileImage" class="file-icon"
                                          size="2x" aria-hidden="true" alt="Image File"
                                          (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                        <p class="file-name pad_10">{{ sharedService.truncateFileName(file.name) }}</p>
                                      </div>
                                      <div>
                                        <button class="delete_btn_icon" (click)="removeFile(quesAns, fileIndex)"
                                          matTooltip="Remove file">
                                          <mat-icon>delete</mat-icon>
                                        </button>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="quesAns.uploading">
                            <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate"
                              [value]="progressValue"></mat-progress-spinner>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <div class="d-flex align_item_center justify_content_center mgn-up-10">
            <button mat-flat-button class="upload-btn pad_10" (click)="updateSurvey()">Submit</button>
          </div>
        </ng-container>
      </ng-container>
    </section>
  </section>
</ng-container>
<ng-container>
  <section>
    <ng-template #congratsTemplate>
      <app-congratulations></app-congratulations>
    </ng-template>
  </section>
</ng-container>