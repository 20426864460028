import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, NO_ERRORS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { MatList, MatListItem } from '@angular/material/list';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatAccordion, MatExpansionModule, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatRadioButton, MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IncompleteQuestionsDialogComponent } from '../incomplete-questions-dialog/incomplete-questions-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileExcel, faFileImage, faFilePdf, faFileWord, faCircle } from '@fortawesome/free-solid-svg-icons';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { CongratulationsComponent } from '../congratulations/congratulations.component';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { SharedService } from '../shared.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TemplateViewerService } from '../template-viewer/template-viewer.service';
import { SnackbarService } from '../template-viewer/snackbar.service';
import { projectMessageLocal } from '../template-viewer/project-message';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-question-answer',
  standalone: true,
  imports: [IncompleteQuestionsDialogComponent, CommonModule, RouterOutlet, MatListItem, MatExpansionPanelDescription, MatList, MatExpansionPanelTitle,
    MatExpansionModule, MatExpansionPanel, MatAccordion, MatRadioButton, MatRadioModule, MatCheckboxModule,
    MatCheckbox, MatCardModule, CapitalizeFirstPipeModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,
    MatIcon, MatProgressSpinnerModule, MatTooltipModule, FontAwesomeModule, MatStepperModule, MatProgressBarModule,
    CongratulationsComponent, MatSelectModule, MatOptionModule, FormsModule,MatButtonModule
    
 
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [TemplateViewerService], // Make sure TemplateViewerService is provided
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class TemplateQuestionAnswerComponent implements AfterViewChecked,OnInit{
  data: any;
  completionPercentage: number = 0;
  key: any;
  startTime!: Date;
  uploading: boolean = false;
  progressValue = 5;
  faFilePdf = faFilePdf;
  faFileWord = faFileWord;
  faFileExcel = faFileExcel;
  faFileImage = faFileImage;
  faCircleIcon = faCircle;
  showCongratulations: boolean = false;
  activeStep: number = 0;
  surveyStarted: boolean = false;
  phoneNumber: string = '+49 8022 18808884'
  selected = ''
  isPositive: boolean = false;
  isActual: boolean = false;
  filteredSection: any;
  @ViewChild('signaturePad', { static: false }) signaturePadElement!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private drawing = false;
  penColor: string = '#000000'; // Default pen color
   history: string[] = []; // Stack to store canvas state
   redoStack: string[] = []; // Stack to store redo actions
   isDrawing: boolean = false; // Track if drawing is in progress
   public signatureData: string = ''; // Store canvas data
   defultImageUrl: string = 'https://live.logincss.com/images/dummy-image.png';//https://assets-staging.sustain-suite.com/uploads/73b0b027acceea4a538a20c1d25d9f3f.jpg
   selectedDropdown!: string; // For single selection
   uplodedFiles:any;
   autoSignatureEnabled: boolean = false;
   typeEmailToPreview:any='section';

  constructor(public templateViewerService: TemplateViewerService, public sharedService: SharedService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService, private fb: FormBuilder,
    private dialog: MatDialog, public cd: ChangeDetectorRef
  ) { 
    
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        console.log(params)
      this.key = params['key'];
    });
    this.startTime = new Date();
    this.loadTemplate(this.key);
  }
  startSurvey() {
    this.surveyStarted = true;
    this.activeStep = 0;

    // this.initializeCanvas();

  }


  loadTemplate(id: any) {
    this.templateViewerService.getSmaSurvey(id).subscribe({
      next: (response: any) => {
        // Parse stringified options
        this.parseOptions(response);
        console.log(response);
        this.data = response;
        this.initializeOptions();
        this.updateCompletion();
        this.cd.detectChanges(); // Trigger change detection

        this.initializeCanvas();

      },
      error: (error: any) => {
        console.log(error)
      }
    });
  }

  // Method to parse stringified options
  parseOptions(response: any) {
    if (response && response.sections) {
      response.sections.forEach((section: any) => {
        section.questions.forEach((question: any) => {
          if (question.options) {
            question.options = question.options.map((optionString: string) => {
              try {
                return JSON.parse(optionString);
              } catch (e) {
                console.error('Failed to parse option string:', optionString);
                return null; // Or handle the error as needed
              }
            }).filter((option: any) => option !== null);
          }
        });
      });
    }
  }

  // updateCompletion() {
  //   let totalQuestions = 0;
  //   let completedQuestions = 0;

  //   this.data.sections.forEach((section: any) => {
  //     section.questions.forEach((question: any) => {
  //       totalQuestions++;
  //       if (question.answer && question.answer.trim() !== '') {
  //         completedQuestions++;
  //       } else if (question.type === 'checkbox') {
  //         if (question.options.some((option: any) => option.checked)) {
  //           completedQuestions++;
  //         }
  //       } else if (question.type === 'radio') {
  //         if (question.selectedOption) {
  //           completedQuestions++;
  //         }
  //       }
  //     });
  //   });

  //   this.completionPercentage = (completedQuestions / totalQuestions) * 100;
  // }

  // updateCompletion() {
  //   let totalQuestions = 0;
  //   let completedQuestions = 0;
  
  //   this.data.sections.forEach((section: any) => {
  //     section.questions.forEach((question: any) => {
  //       totalQuestions++;
        
  //       // Check for different question types
  //       if (question.type === 'textarea' || question.type === 'input') {
  //         if (typeof question.answer === 'string' && question.answer.trim() !== '') {
  //           completedQuestions++;
  //         }
  //       } else if (question.type === 'checkbox') {
  //         if (question.options.some((option: any) => option.checked)) {
  //           completedQuestions++;
  //         }
  //       } else if (question.type === 'radio') {
  //         if (question.selectedOption) {
  //           completedQuestions++;
  //         }
  //       } else if (question.type === 'dropdown' || question.type === 'ratingScale') {
  //         // Handle dropdowns and rating scales
  //         if (Array.isArray(question.answer) && question.answer.length > 0) {
  //           completedQuestions++;
  //         } else if (question.answer) {
  //           completedQuestions++;
  //         }
  //       }
  //     });
  //   });
  
  //   this.completionPercentage = (completedQuestions / totalQuestions) * 100;
  // }


  updateCompletion() {
    let totalQuestions = 0;
    let completedQuestions = 0;

    this.data.sections.forEach((section: any) => {
        section.questions.forEach((question: any) => {
            totalQuestions++;

            // Check for different question types
            switch (question.type) {
                case 'textarea':
                case 'input':
                    if (typeof question.answer === 'string' && question.answer.trim() !== '') {
                        completedQuestions++;
                    }
                    break;
                
                case 'checkbox':
                    if (question.options.some((option: any) => option.checked)) {
                        completedQuestions++;
                    }
                    break;
                
                case 'radio':
                    if (question.selectedOption) {
                        completedQuestions++;
                    }
                    break;
                
                case 'dropdown':
                    if (Array.isArray(question.answer) && question.answer.length > 0) {
                        completedQuestions++;
                    } else if (question.answer && question.answer.trim() !== '') {
                        completedQuestions++;
                    }
                    break;

                case 'signature':
                    // Assuming signature is complete if it has data
                    if (question.answer) {
                        completedQuestions++;
                    }
                    break;

                case 'fileUpload':
                    // Assuming upload is complete if there are uploaded files
                    if (question.uploadedFiles && question.uploadedFiles.length > 0) {
                        completedQuestions++;
                    }
                    break;

                case 'ratingScale':
                    // Assuming completed if at least one rating is selected
                    if (question.options.some((option: any) => option.answer && option.answer.some((ans: any) => ans))) {
                        completedQuestions++;
                    }
                    break;

                default:
                    break; // Ignore unknown types
            }
        });
    });

    this.completionPercentage = (completedQuestions / totalQuestions) * 100;
}

  


  updateSurvey() {
    console.log((this.surveyDataForAPI(this.data)))
    const incompleteQuestions = this.validateSurvey();
    console.log('incompleteQuestions',incompleteQuestions)
    if (incompleteQuestions.length > 0) {
      this.dialog.open(IncompleteQuestionsDialogComponent, {
          height: '600px',
          data: { incompleteQuestions, submittedQuestions: [], confirmation: false },
          autoFocus: false,
      })
      return;
  }
    const submittedQuestions = this.getSubmittedQuestions();
    console.log('submittedQuestions',submittedQuestions)
    this.dialog.open(IncompleteQuestionsDialogComponent, {
      height: "600px",
      data: { incompleteQuestions: [], submittedQuestions, confirmation: true },
      autoFocus: false, // Disable auto focus
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const surveyData = this.surveyDataForAPI(this.data);
        console.log(surveyData);
        this.templateViewerService.UpdateSurvey(surveyData).subscribe({
          next: (response: any) => {
            if (response) {
              console.log(response);
              this.showCongratulations = true;
              // let message: string = projectMessageLocal.SURVEY_ADDED
              // this.snackbarService.openSnackBar(message, { panelClass: 'snackbarnormal' });
              this.resetTimer(); // Reset the timer after successful submission
            }
          },
          error: (error: any) => {
            console.log(error);
            let errorMsg: string = projectMessageLocal.SURVEY_FAILED;
            this.snackbarService.openSnackBar(errorMsg, { panelClass: 'snackbarerror' })
          }
        });
      }
    });
  }

  scrollToQuestion(sectionIndex: number, questionIndex: number) {
    const questionId = `question-${sectionIndex}-${questionIndex}`;
    const element = document.getElementById(questionId);
    console.log(element)

    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
        console.warn(`Element with ID ${questionId} not found.`);
    }
}
getIndicesFromQuestion(question: string): [number, number] {
  // Logic to find the sectionIndex and questionIndex based on your data structure
  for (let sectionIndex = 0; sectionIndex < this.data.sections.length; sectionIndex++) {
      const questionIndex = this.data.sections[sectionIndex].questions.indexOf(question);
      if (questionIndex !== -1) {
          return [sectionIndex, questionIndex];
      }
  }
  return [-1, -1]; // Not found
}



  // validateSurvey(): string[] {
  //   const incompleteQuestions: string[] = [];
  //   this.data.sections.forEach((section: any) => {
  //     section.questions.forEach((question: any) => {
  //       if (question.required) {
  //         let answer;
  //         if (question.type === 'checkbox') {
  //           answer = question.options.some((option: any) => option.checked);
  //         } else if (question.type === 'radio') {
  //           answer = question.selectedOption;
  //         } else if (question.type === 'input' || question.type === 'textarea') {
  //           answer = question.answer;
  //         }
  //         else if (question.type === 'fileUpload') {
  //           answer = question.uploadedFiles && question.uploadedFiles.length > 0;
  //       } else if (question.type === 'ratingScale') {
  //           answer = question.options.some((option: any) => option.answer.some((ans: any) => ans));
  //       }
  //         if (!answer || (Array.isArray(answer) && answer.length === 0)) {
  //           incompleteQuestions.push(question.text);
  //         }
  //       }
  //     });
  //   });
  //   return incompleteQuestions;
  // }

  validateSurvey(): string[] {
    const incompleteQuestions: string[] = [];
    
    this.data.sections.forEach((section: any) => {
        section.questions.forEach((question: any) => {
          // console.log('question',question)
            if (question.required) {
                let answer;
                
                switch (question.type) {
                    case 'checkbox':
                        // answer = question.options.some((option: any) => option.checked);
                        answer = question.options.some((option: any) => 
                          option.answer.some((item: any) => item.checked)
                        );
                        break;
                    case 'radio':
                        answer = question.selectedOption;
                        break;
                    case 'input':
                    case 'textarea':
                        answer = question.options[0].answer 
                        break;
                    case 'fileUpload':
                        answer = question.uploadedFiles && question.uploadedFiles.length > 0;
                        break;
                    case 'signature':
                        answer = question.answer; // Check if signature is present
                        break;
                    case 'ratingScale':
                        answer = question.options.some((option: any) => option.answer.some((ans: any) => ans));
                        break;
                    case 'dropdown':
                        // Adjust this check for both single and multiple selection scenarios
                        answer = question.answer && (Array.isArray(question.answer) ? question.answer.length > 0 : question.answer.trim() !== '');
                        break;
                    default:
                        answer = false; // Unknown type, mark as incomplete
                        break;
                }

                // Check for completeness
                if (!answer || (Array.isArray(answer) && answer.length === 0)) {
                    incompleteQuestions.push(question.text);
                }
            }
        });
    });

    return incompleteQuestions;
}



  // getSubmittedQuestions(): any {
  //   const sections: any[] = [];

  //   this.data.sections.forEach((section: any) => {
  //     const sectionData: any = {
  //       title: section.title,
  //       description: section.description || '',
  //       questions: []
  //     };

  //     section.questions.forEach((question: any) => {
  //       const questionData: any = {
  //         text: question.text,
  //         description: question.description || '',
  //         type: question.type,
  //         options: [],
  //         answer: ''
  //       };

  //       if (question.type === 'checkbox') {
  //         questionData.options = question.options.map((option: any) => {
  //           if (typeof option.value === 'object') {
  //             return option.value.answer || JSON.stringify(option.value);
  //           }
  //           return option.value;
  //         });

  //         const selectedOptions = question.options
  //           .filter((option: any) => option.checked)
  //           .map((option: any) => {
  //             if (typeof option.value === 'object') {
  //               return option.value.answer || JSON.stringify(option.value);
  //             }
  //             return option.value;
  //           });

  //         if (selectedOptions.length) {
  //           questionData.answer = selectedOptions;
  //         }
  //       } else if (question.type === 'radio') {
  //         questionData.options = question.options.map((option: any) => option.answer);

  //         if (question.selectedOption) {
  //           questionData.answer = question.selectedOption;
  //         }
  //       } else if (question.type === 'input' || question.type === 'textarea') {
  //         if (question.answer) {
  //           questionData.answer = question.answer;
  //         }
  //       }
  //       if (question.fileUpload && question.uploadedFiles && question.uploadedFiles.length > 0) {
  //         questionData.uploadedFiles = question.uploadedFiles.map((file: any) => ({
  //           name: file.name,
  //           url: file.url
  //         }));
  //         questionData.fileUpload = question.fileUpload
  //       }
  //       sectionData.questions.push(questionData);
  //     });

  //     sections.push(sectionData);
  //   });

  //   return sections;
  // }

  getSubmittedQuestions(): any {
    const sections: any[] = [];

    this.data.sections.forEach((section: any) => {
      console.log('section',section)
        const sectionData: any = {
            title: section.title,
            description: section.description || '',
            questions: []
        };

        section.questions.forEach((question: any) => {
          console.log('question',question)
            const questionData: any = {
                text: question.text,
                description: question.description || '',
                type: question.type,
                options: [],
                answer: ''
            };

            // if (question.type === 'checkbox') {
            //     questionData.options = question.options.map((option: any) => option);

            //     if (question.answer && question.answer.length) {
            //         questionData.answer = question.answer.map((ans: any) => ans[0]);
            //     }
            // } 

            if (question.type === 'checkbox') {
              questionData.options = question.options.map((option: any) => option.answer[0]);
              questionData.answer = question.options
                  .filter((option: any) => option.checked)
                  .map((option: any) => option.answer[0]);
          } 
            else if (question.type === 'radio' || question.type === 'dropdown') {
                questionData.options = question.options.map((option: any) => option);
                if (question.answer) {
                    questionData.answer = question.answer;
                }
            } 
            else if (question.type === 'input' || question.type === 'textarea') {
              questionData.answer = question.options.map((option: any) => option.answer);
                if (question.answer) {
                    questionData.answer = question.answer;
                }
            } 
            else if (question.type === 'fileUpload' && question.uploadedFiles && question.uploadedFiles.length > 0) {
                questionData.uploadedFiles = question.uploadedFiles.map((file: any) => ({
                    name: file.name,
                    url: file.url
                }));
            } 
            else if (question.type === 'signature' ) {
                if (question.answer) {
                    questionData.answer = question.answer;
                }
            }
            else if ( question.type === 'ratingScale') {
              // if (question.answer) {
              //     questionData.answer = question.answer;
              // }
              questionData.options = question.options.map((option: any) => option);

                if (question.answer) {
                    questionData.answer = question.answer;
                }
          }

            sectionData.questions.push(questionData);
        });

        sections.push(sectionData);
    });

    return sections;
}


   surveyDataForAPI(data: any): any {
    let isComplete = true;
    const sections = data.sections.map((section: any) => ({
      title: section.title,
      description: section.description,
      questions: section.questions.map((question: any) => {
        console.log('question',question)
        let answer;
        let otherOptions;
        if (question.type === 'checkbox') {
          answer = question.options.filter((option: any) =>
            option.checked).map((option: any) =>
              option.value);
        } else if (question.type === 'radio') {
          answer = question.selectedOption;
          console.log('answer',answer)
        } else if (question.type === 'input' || question.type === 'textarea') {
          // answer = question.answer;
          // console.log('answer',answer)

          answer = question.options.length > 0 ? question.options[0] : undefined; // Adjusted here
                console.log('answer', answer);
        }
        else if (question.type === 'signature') {
          answer = question; 
          console.log(' question.answer', answer)

        }
        else if (question.type === 'fileUpload') {
          answer = question; 
        }
        else if (question.type === 'ratingScale') {
          answer = question.options.map((option: any) => 
            option.answer.map((ans: string) => ans ? option.selectedIcon : null).filter((icon: any) => icon)
          ).flat();
 
        }

        else if (question.type === 'dropdown') {
          if (question.options[0].multipleDropdown === 'multiple_select') {
              answer = question || []; 
          } else {
              answer = question.answer ? question: undefined;
          }
      }
      
        if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          isComplete = false;
        }
        const uploadedFile = question.uploadedFiles ? question.uploadedFiles : null;
        return {
          description: question.description,
          text: question.text,
          type: question.type,
          options: question.type === 'checkbox' || question.type === 'radio' ? question.options : null,
          answer: answer,
          uploadedFile: uploadedFile
        };
      })
    }))
    console.log(sections);
    const endTime = new Date();
    const duration = this.formatDuration(endTime.getTime() - this.startTime.getTime()); // Duration in timestamp format
    console.log('sections',sections)
    return {
      surveyId: data.id,
      ctxId: data.ctxId,
      userId: data.userId,
      title: data.title,
      description: data.description,
      image: data.image,
      questions: JSON.stringify(sections),
      status: isComplete ? 'COMPLETED' : 'INCOMPLETE',
      duration: duration // Include duration in the API data
    };
  }

  initializeOptions() {
    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        // if (question.type === 'checkbox') {
        //   question.options = question.options.map((option: any) => ({
        //     value: option,
        //     checked: false
        //   }));
        // }
        if (question.type === 'checkbox') {
          question.options.forEach((option:any) => {
            option.answer = option.answer.map((answer: any) => ({
              text: answer,
              checked: false
            }));
          });
        }
        if (question.type === 'radio' && question.options.some((option: any) => option.answer === 'Yes' || option.answer === 'No')) {
          question.showFurtherOptions = false;
        }
        if (question.type === 'dropdown') {
          question.answer = question.multipleDropdown === 'multiple_select' ? [] : null; 
        }
      });
    });
  }

  
  
  
  
  
 

  onDropdownChange(quesAns: any, event: Event) {
    const target = event.target as HTMLSelectElement; // Cast to HTMLSelectElement
    const selectedValue = target.value; // Now you can safely access 'value'
    
    quesAns.answer = selectedValue; // Update only when a selection is made
}

  onCheckboxChangeForMultiselect(quesAns: any, dropdownOption: string) {
    const answerArray: string[] = quesAns.answer || [];
    if (answerArray.includes(dropdownOption)) {
        quesAns.answer = answerArray.filter(option => option !== dropdownOption);
    } else {
        answerArray.push(dropdownOption);
        quesAns.answer = answerArray; 
    }
}

  

  onCheckboxChange(question: any, option: any) {
    option.checked = option.checked;
    this.updateCompletion();
    this.cd.detectChanges();
  }

  onRadioChange(question: any, selectedOption: any, sectionIndex: number, questionIndex: number) {
    console.log('question', question)
    console.log('selectedOption', selectedOption)
    question.selectedOption = selectedOption;
    this.isPositive = false;
    this.isActual = false;
    if (question.text === "Positive/Negative") {
      if (question.selectedOption === 'Positive') {
        const hasActualPotentialQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Actual/Potential" && question.selectedOption === "Actual"
          ));
        if(hasActualPotentialQuestion) {
          this.isActual = true;
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Irremediability") && !sections.title.includes("Likelyhood"));
            this.isPositive = true;
        } else {
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Irremediability"));
           this.isPositive = true;
        }
      } else {
        const hasActualPotentialQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Actual/Potential" && question.selectedOption === "Actual"
          ));
          if(hasActualPotentialQuestion) {
            this.isActual = true;
            this.filteredSection = this.data.sections.filter((sections: any) =>
              !sections.title.includes("Likelyhood"));
          }
      }
    } else if(question.text === "Actual/Potential") {
      if(question.selectedOption === 'Actual') {
        const hasPositiveNegativelQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Positive/Negative" && question.selectedOption === "Positive"
          ));
        if(hasPositiveNegativelQuestion) {
          this.isPositive = true;
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Likelyhood") && !sections.title.includes("Irremediability"));
            this.isActual = true;
        } else {
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Likelyhood"));
            this.isActual = true;
        }
      } else {
        const hasPositiveNegativeQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Positive/Negative" && question.selectedOption === "Positive"
          ));
          if(hasPositiveNegativeQuestion) {
            this.isPositive = true;
            this.filteredSection = this.data.sections.filter((sections: any) =>
              !sections.title.includes("Irremediability"));
          }
      }
    }
this.getGlobalQuestionIndex(sectionIndex, questionIndex);
    this.updateCompletion();
    if (selectedOption === 'Yes') {
      // question.showFurtherOptions = true;
    } else {
      // question.showFurtherOptions = false;
    }
  }

  getGlobalQuestionIndex(sectionIndex: number, questionIndex: number): number {
    let globalIndex = 0;
  
    // Loop through all sections up to the current section
    for (let i = 0; i < sectionIndex; i++) {
      // Only count questions that are not of type 'section'
      globalIndex += this.data.sections[i].questions.filter((q: { type: string; }) => q.type !== 'section').length;
    }
  
    // Add the questions in the current section, skipping 'section' types
    for (let j = 0; j <= questionIndex; j++) {
      if (this.data.sections[sectionIndex].questions[j].type !== 'section') {
        globalIndex++;
      }
    }
  
    return globalIndex;  // This gives you the final question number, skipping sections
  }
  

  resetTimer() {
    this.startTime = new Date(); // Reset the start time for the next survey attempt
  }

  private formatDuration(durationInMilliseconds: number): string {
    const totalSeconds = Math.floor(durationInMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  onFileChange(event: any, quesAns: any): void {
    const files: File[] = Array.from(event.target.files);
    const allowedFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpg', 'jpeg'];
    const existingFiles = quesAns.uploadedFiles || [];
    // Check if the number of selected files plus existing files exceeds the limit
    if (existingFiles.length + files.length > 5) {
      this.snackbarService.openSnackBar('You can only upload a maximum of 5 files.', { panelClass: 'snackbarerror' });
      return;
    }

    // Check if the number of selected files exceeds the limit
    if (files.length > 5) {
      this.snackbarService.openSnackBar('You can only upload a maximum of 5 files.', { panelClass: 'snackbarerror' });
      return;
    }
    // Check if all files are of the allowed types
    const invalidFiles = files.filter(file => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      return !allowedFileTypes.includes(extension!);
    });

    if (invalidFiles.length > 0) {
      this.snackbarService.openSnackBar('Only PDF, Word, Excel, and Image files can be uploaded.', { panelClass: 'snackbarerror' });
      return;
    }
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });
      quesAns.uploading = true;
      this.templateViewerService.fileUpload(formData).subscribe({
        next: (response: any) => {
          console.log('API Response:', response);
          const fileUrl: string[] = JSON.parse(response);
          // Assuming response is a single URL or similar
          if (fileUrl.length === files.length) {
            quesAns.uploadedFiles = files.map((file, index) => ({
              name: file.name,
              url: fileUrl[index]
            }));
          }
          quesAns.uploadedFiles = [...existingFiles, ...quesAns.uploadedFiles];          
          quesAns.uploading = false;
          this.updateCompletion();
          this.snackbarService.openSnackBar('Files uploaded successfully.', { panelClass: 'snackbarnormal' });
        },
        error: (error: any) => {
          console.log('error', error);
          this.snackbarService.openSnackBar('File upload failed.', { panelClass: 'snackbarerror' });
          quesAns.uploading = false;
        }
      });
    }
  }



  removeFile(quesAns: any, index: number): void {
    quesAns.uploadedFiles.splice(index, 1);
    this.snackbarService.openSnackBar('File removed successfully.', { panelClass: 'snackbarnormal' });
    this.updateCompletion();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  getPointerPosition(): string {
    return `${this.completionPercentage}%`;
  }

  getDropdownList(dropdownAns: any): string {
    if (Array.isArray(dropdownAns)) {
        // Join all items in the array into a string, trimming each item
        return dropdownAns.map(item => item.trim()).join(', ');
    } else if (typeof dropdownAns === 'string') {
        // If it's a string, split on newlines or commas
        return dropdownAns.split(/[\n,]+/).map(item => item.trim()).join(', ');
    } else {
        console.error('Invalid input: dropdownAns is neither a string nor an array', dropdownAns);
        return '';
    }
}




  trackByFn(item: any) {
    return item.name; // or any unique identifier
  }

  posNegativeActionPerform(Event: any) {
    console.log('Event', Event.value)
    // const irremediabilityControls = [
    //     { name: 'irremediabilityResponse' },
    //     { name: 'irremediabilityRationale' },
    //     { name: 'irremediabilityDataSource' }
    // ];
    // if(Event.value==='Positive' || Event.value==='Uncertain' ){
    //     this.hideIrremediabilityResponse =true;
    //     this.sharedService.removeFormFieldControl(this.iro_form,irremediabilityControls);
    // }
    // else{
    //     this.hideIrremediabilityResponse =false;
    //     this.sharedService.addFormFieldControl(this.iro_form,irremediabilityControls)
    // }

  } 
  

  startDrawing(event: MouseEvent) {
    this.drawing = true;
    this.ctx.beginPath();
    this.ctx.moveTo(event.offsetX, event.offsetY);
  }

  stopDrawing() {
    this.drawing = false;
    this.ctx.closePath();
    this.saveState(); // Save the state after drawing
  }

  draw(event: MouseEvent) {
    if (!this.drawing) return;
    this.ctx.lineTo(event.offsetX, event.offsetY);
    this.ctx.stroke();
  }

  clearSignature() {
    const canvas = this.signaturePadElement.nativeElement;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.history = []; // Clear history on clear
    this.redoStack = []; // Clear redo stack
    this.signatureData =''
  }

  saveSignature(quesAns:any) {
    const canvas = this.signaturePadElement.nativeElement;
    this.signatureData = canvas.toDataURL('image/png');
    quesAns.answer = this.signatureData; 
    console.log( this.signatureData);
  }
  initializeCanvas() {
    if (this.signaturePadElement && this.signaturePadElement.nativeElement) {
      const canvas = this.signaturePadElement.nativeElement;
      this.ctx = canvas.getContext('2d')!;
      this.ctx.lineWidth = 2;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = this.penColor;
      this.addCanvasEventListeners();
      this.saveState();

    } else {
      console.error('Canvas element not found');
    }
  }
  updatePenColor() {
    if (this.ctx) {
      this.ctx.strokeStyle = this.penColor;
    }
  }
  
  // canvas: HTMLCanvasElement
  addCanvasEventListeners() {
    const canvas = this.signaturePadElement.nativeElement;

    canvas.addEventListener('mousedown', (event) => this.startDrawing(event));
    canvas.addEventListener('mousemove', (event) => this.draw(event));
    canvas.addEventListener('mouseup', () => this.stopDrawing());
    canvas.addEventListener('mouseout', () => this.stopDrawing());
  }
  
  clearCanvasListeners(canvas: HTMLCanvasElement) {
    canvas.removeEventListener('mousedown', this.startDrawing.bind(this));
    canvas.removeEventListener('mouseup', this.stopDrawing.bind(this));
    canvas.removeEventListener('mousemove', this.draw.bind(this));
    canvas.removeEventListener('mouseout', this.stopDrawing.bind(this));
  }
  saveState() {
    const canvas = this.signaturePadElement.nativeElement;
    this.signatureData = canvas.toDataURL(); // Save canvas as a data URL
    this.history.push(canvas.toDataURL());
    // this.redoStack = []; 
  }

  undo() {
    if (this.history.length > 0) {
      const currentState = this.signaturePadElement.nativeElement.toDataURL();
      this.redoStack.push(currentState); 
        const lastState = this.history.pop(); 
      if (lastState) {
        const canvas = this.signaturePadElement.nativeElement;
        const img = new Image();
        img.src = lastState; 
        img.onload = () => {
          this.ctx.clearRect(0, 0, canvas.width, canvas.height);
          this.ctx.drawImage(img, 0, 0);
        };
      }
    }
  }
  
  redo() {
    if (this.redoStack.length > 0) {
      const redoState = this.redoStack.pop();
  
      if (redoState) {
        const canvas = this.signaturePadElement.nativeElement;
        const img = new Image();
        img.src = redoState;
        img.onload = () => {
          this.ctx.clearRect(0, 0, canvas.width, canvas.height);
          this.ctx.drawImage(img, 0, 0);
        };
  
        const currentState = this.signaturePadElement.nativeElement.toDataURL();
        this.history.push(currentState);
      }
    }
  }
  loadSignature() {
    if (this.signatureData) {
      const img = new Image();
      img.src = this.signatureData;
      img.onload = () => {
        this.ctx.clearRect(0, 0, this.signaturePadElement.nativeElement.width, this.signaturePadElement.nativeElement.height);
        this.ctx.drawImage(img, 0, 0);
      };
    }
  }

  isImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  selectFiles(event: any, quesAns: any): void {
    const files: File[] = Array.from(event.target.files);
    const existingFiles = quesAns.uploadedFiles || [];
  
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });
      quesAns.uploading = true;
      this.templateViewerService.fileUpload(formData).subscribe({
        next: (response: any) => {
          console.log('API Response:', response);
          const fileUrl: string[] = JSON.parse(response);
          if (fileUrl.length === files.length) {
            const newFiles = files.map((file, index) => ({
              name: file.name,
              url: fileUrl[index]
            }));
            if (quesAns.uploadingFile === 'single') {
              quesAns.uploadedFiles = [newFiles[newFiles.length - 1]]; 
            } else {
              quesAns.uploadedFiles = [...existingFiles, ...newFiles];
            }
          }
          this.defultImageUrl = '';
          this.uplodedFiles = quesAns.uploadedFiles;
          console.log('uplodedFiles', this.uplodedFiles);
          quesAns.uploading = false;
          this.updateCompletion();
          this.snackbarService.openSnackBar('Files uploaded successfully.', { panelClass: 'snackbarnormal' });
        },
        error: (error: any) => {
          console.log('error', error);
          this.snackbarService.openSnackBar('File upload failed.', { panelClass: 'snackbarerror' });
          quesAns.uploading = false;
        }
      });
    }
  }
  
  toggleRating(option: any, index: number) {
    // Logic to handle selection
    if (option.answer[index] === 'selected') {
      option.answer[index] = ''; // Unselect
    } else {
      option.answer[index] = 'selected'; // Select
    }
  }
  deleteFile(index: number) {
    this.uplodedFiles.splice(index, 1);
    this.updateSubmittedQuestions();

  }
  updateSubmittedQuestions() {
    const submittedQuestions = this.getSubmittedQuestions();
    console.log('submittedQuestions', submittedQuestions);
  }
  toggleAutoSignature() {
    this.autoSignatureEnabled = !this.autoSignatureEnabled;
    if (this.autoSignatureEnabled) {
      this.drawAutoSignature();
    }
  }

  drawAutoSignature() {
    const canvas = this.signaturePadElement.nativeElement;
    const context = canvas.getContext('2d');
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.strokeStyle = this.penColor;
      context.lineWidth = 2;

      // Example auto-signature drawing logic
      context.beginPath();
      context.moveTo(50, 150);
      context.lineTo(150, 50);
      context.lineTo(250, 150);
      context.stroke();
    }
  }
  
  isHtmlContent(str: string): boolean {
    // Regular expression to detect HTML tags
    const regex = /<[^>]*>/;
    return regex.test(str);
  }
  

  
}